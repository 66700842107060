<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <b-tab active>
      <template #title>
        <feather-icon
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Tenant.General info') }}</span>
      </template>

      <tenant-settings-general />
    </b-tab>

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import TenantSettingsGeneral from './TenantSettingsGeneral.vue'

export default {
  components: {
    BTabs,
    BTab,
    TenantSettingsGeneral,
  },
  data() {
    return {
      options: {},
    }
  },
}
</script>
