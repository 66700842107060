import store from '@/store'
import {
  ref, reactive, watch, computed,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import i18n from '@/libs/i18n'

export default function useTenantSettings() {
  const toast = useToast()

  const tenantData = computed(() => store.state.auth.company)

  const fetchTenantData = async uuid => {
    await store.dispatch('model/tenant/fetchTenant', uuid)
      .then(response => {
        store.commit('auth/SET_COMPANY', response.data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          store.commit('auth/SET_COMPANY', null)
        }
      })
  }

  const saveTenantData = formData => {
    const params = new FormData()

    params.append('address_1', formData.tenantData.address_1)
    params.append('address_2', formData.tenantData.address_2)
    params.append('city', formData.tenantData.city)
    params.append('company', formData.tenantData.company)
    params.append('email', formData.tenantData.email)
    params.append('legal_id', formData.tenantData.legal_id)
    params.append('newLogo', formData.newLogo)
    params.append('newImage', formData.newStartImage)
    params.append('name', formData.tenantData.name)
    params.append('postal_code', formData.tenantData.postal_code)
    params.append('state', formData.tenantData.state)
    params.append('uuid', formData.tenantData.uuid)

    const res = store.dispatch('model/tenant/updateTenant', params)
      .then(response => {
        store.commit('auth/SET_COMPANY', response.data)
        return response
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('users.update_failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
      })

    return res
  }

  const delay = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds))

  return {
    tenantData,
    fetchTenantData,
    saveTenantData,
    delay,
  }
}
