<template>
  <b-overlay
    :show="saving"
    variant="transparent"
    opacity="1"
    blur="5px"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card>
      <b-row>
        <b-col sm="2">
          <b-form-group
            :label="$t('Tenant.Logo')"
            label-for="new-logo"
          >
            <b-avatar
              size="lg"
              :src="tenantData.logo"
              class="mb-1"
            />
          </b-form-group>
        </b-col>
        <b-col sm="8">
          <b-form-file
            class="mt-2"
            id="new-logo"
            :placeholder="$t('Select a file to upload')"
            drop-placeholder="Drop file here"
            @change="handleLogoUpload($event)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('start_image')"
            label-for="new-start_image"
          >
            <b-img
              thumbnail
              size="lg"
              :src="tenantData.start_image"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-file
            id="new-start_image"
            :placeholder="$t('Select a file to upload') "
            drop-placeholder="Drop file here"
            @change="handleStartImageUpload($event)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            :label="$t('Tenant.company_name')"
            label-for="surname"
            class="mt-2"
          >
            <b-form-input
              v-model="tenantData.company"
              name="surname"
              @keyup="onChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('users.legal_id')"
              label-for="legalId"
            >
              <b-form-input
                v-model="tenantData.legal_id"
                name="legalId"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.address')"
              label-for="address1"
            >
              <b-form-input
                v-model="tenantData.address_1"
                name="address1"
                @keyup="onChange"
              />
              <br>
              <b-form-input
                v-model="tenantData.address_2"
                name="address2"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.postal_code')"
              label-for="postalCode"
            >
              <b-form-input
                v-model="tenantData.postal_code"
                name="postalCode"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.city')"
              label-for="city"
            >
              <b-form-input
                v-model="tenantData.city"
                name="city"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.state')"
              label-for="state"
            >
              <b-form-input
                v-model="tenantData.state"
                name="state"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('Tenant.responsible')"
              label-for="name"
            >
              <b-form-input
                v-model="tenantData.name"
                name="name"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.email')"
              label-for="email"
            >
              <b-form-input
                v-model="tenantData.email"
                name="email"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <span class="alert-success">
              {{ updateMessage }}
            </span>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="disableSaveButton"
              @click.prevent="saveChanges"
            >
              {{ $t('Save changes') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              {{ $t('reset_form') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BOverlay, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import useTenantSettings from './useTenantSettings'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BOverlay,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const saving = ref(false)
    const disableSaveButton = ref(false)
    const profileFile = ref(null)
    const tenantStoredData = ref(JSON.parse(localStorage.getItem('currentCompany')))
    const updateMessage = ref(null)

    let newLogo = null
    let newStartImage = null

    const {
     tenantData, fetchTenantData, saveTenantData, delay,
    } = useTenantSettings()

    const onChange = () => {
      disableSaveButton.value = false
      updateMessage.value = null
    }

    const handleLogoUpload = e => {
      newLogo = e.target.files[0]
    }

    const handleStartImageUpload = e => {
      newStartImage = e.target.files[0]
    }

    const saveChanges = async () => {
      saving.value = true

      const formData = {}
      formData.tenantData = tenantData.value
      formData.newLogo = newLogo
      formData.newStartImage = newStartImage
      formData.uuid = tenantStoredData.value.uuid

      await saveTenantData(formData).then(async response => {
        if (response.status === 200) {
          updateMessage.value = i18n.t('users.update_successful')
        }
      })

      saving.value = false

      await delay(3000)

      updateMessage.value = null
    }

    const resetForm = () => {
      // Pendiente: reseteo de formulario con los valores originales
      fetchTenantData(tenantStoredData.value.uuid)
    }

    onMounted(async () => {
      await fetchTenantData(tenantStoredData.value.uuid)
    })

    return {
      saving,
      disableSaveButton,
      resetForm,
      tenantData,
      profileFile,
      saveChanges,
      onChange,
      updateMessage,
      handleLogoUpload,
      handleStartImageUpload,
    }
  },
}
</script>
